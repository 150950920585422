<template>
    <div class="admin-settings-trnaslations">
        <b-card class="cms-pages-admin" :title="$t('Providers')">
            <b-row>
                <b-col cols="12">
                    <good-data-table 
                        ref="providerTable" 
                        :columns="fields" :no-serial="true"
                        :total-column="'response.responseData.total'" 
                        :data-column="'response.responseData.data'"
                        :api-endpoint="'/admin/provider'"
                         @add-new-info="toggelModal = true" 
                    />
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
    
<script>
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { dateTimeFormat, isEmpty } from '@/utilities';
export default {
    name: "ProviderListing",
    components: {
        GoodDataTable,
    },
    data: () => ({
    }),
    computed: {
        fields: {
            get() {
                return [
                    {
                        field: 'first_name',
                        label: 'First Name',
                        sortable: false,
                    },
                    {
                        field: 'last_name',
                        label: 'Last Name',
                        sortable: false,
                    },
                    {
                        field: 'email',
                        label: 'Email',
                        sortable: false,
                    },
                    {
                        field: 'country_name',
                        label: 'Country',
                        useResolver: true,
                        useHtml: true,
                        renderer: props => isEmpty(props.country) ? 'N/A' : props.country.country_name,
                    },
                    {
                        field: 'number',
                        label: 'Number',
                        useResolver: true,
                        useHtml: true,
                        renderer: props => isEmpty(props.mobile) ? 'N/A' : `${props.country_code}${props.mobile}`,
                    },
                    {
                        field: 'status',
                        label: 'Status',
                        // sortable: false,
                        // useHtml: true,
                        // renderer: props => props.status ? 'Inactive' : 'Active',
                    },
                    {
                        field: 'created_at',
                        label: 'Created At',
                        useHtml: true,
                        renderer: props => isEmpty(props.created_at) ? 'N/A' : dateTimeFormat(props.created_at, 'DD MMM, YYYY'),
                    },
                ];
            }
        }
    },
    mounted(){
        this.$refs.providerTable.loadItems();
    }
}
</script>
    